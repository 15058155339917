code {
    font-family: monospace;
    font-size: 16px;
    padding: 1rem;
}

code.highlighter-rouge {
    background: #f9f9fa;
    padding: 4px;
}

.readme_container code {
  padding: 0.1em 0.3em 0 0;
  background: #f9f9fa;
}

blockquote {
  border-left: 4px solid black;
  padding-left: 1rem;
}

/** Set `margin-bottom` to maintain vertical rhythm */
.highlight { margin-bottom: 15px; }

/** Syntax highlighting styles */
.highlight { color: white; background: #272822; }

.highlight pre { tab-size: 2; border: none; }

.highlight pre code { display: block; white-space: pre; overflow-x: auto; word-wrap: normal; }

.highlight .lineno { color: #ccc; display: inline-block; padding: 0 5px; border-right: 1px solid #ccc; -webkit-user-select: none; -ms-user-select: none; -moz-user-select: none; -o-user-select: none; user-select: none; }

.highlight .c { color: #75715e; font-style: italic; }

.highlight .err { color: #960050; background-color: #1e0010; }

.highlight .k { color: #66d9ef; font-weight: bold; }

.highlight .o { color: #f92672; font-weight: bold; }

.highlight .l { color: #ae81ff; }

.highlight .n { color: #f8f8f2; }

.highlight .p { color: #f8f8f2; }

.highlight .cm { color: #75715e; font-style: italic; }

.highlight .cp { color: #75715e; font-weight: bold; }

.highlight .c1 { color: #75715e; font-style: italic; }

.highlight .cs { color: #75715e; font-weight: bold; font-style: italic; }

.highlight .gd { color: #f92672; background-color: #fdd; }

.highlight .gd .x { color: #000; background-color: #faa; }

.highlight .ge { font-style: italic; }

.highlight .gr { color: #a00; }

.highlight .gh { color: #999; }

.highlight .gi { color: #a6e22e; background-color: #dfd; }

.highlight .gi .x { color: #a6e22e; background-color: #afa; }

.highlight .go { color: #888; }

.highlight .gp { color: #555; }

.highlight .gs { font-weight: bold; }

.highlight .gu { color: #75715e; }

.highlight .gt { color: #a00; }

.highlight .kc { color: #66d9ef; font-weight: bold; }

.highlight .kd { color: #66d9ef; font-weight: bold; }

.highlight .kn { color: #f92672; }

.highlight .kp { color: #66d9ef; font-weight: bold; }

.highlight .kr { color: #66d9ef; font-weight: bold; }

.highlight .kt { color: #66d9ef; font-weight: bold; }

.highlight .m { color: #ae81ff; }

.highlight .s { color: #e6db74; }

.highlight .ld { color: #e6db74; }

.highlight .na { color: #a6e22e; }

.highlight .nb { color: #f8f8f2; }

.highlight .nc { color: #a6e22e; font-weight: bold; }

.highlight .no { color: #66d9ef; }

.highlight .nd { color: #a6e22e; }

.highlight .ni { color: #f8f8f2; }

.highlight .ne { color: #a6e22e; }

.highlight .nf { color: #a6e22e; }

.highlight .nl { color: #f8f8f2; }

.highlight .nn { color: #f8f8f2; }

.highlight .nx { color: #a6e22e; }

.highlight .py { color: #f8f8f2; }

.highlight .nt { color: #f92672; }

.highlight .nv { color: #f8f8f2; }

.highlight .ow { color: #f92672; font-weight: bold; }

.highlight .w { color: #f8f8f2; }

.highlight .mf { color: #ae81ff; }

.highlight .mh { color: #ae81ff; }

.highlight .mi { color: #ae81ff; }

.highlight .mo { color: #ae81ff; }

.highlight .sb { color: #e6db74; }

.highlight .sc { color: #e6db74; }

.highlight .sd { color: #e6db74; }

.highlight .s2 { color: #e6db74; }

.highlight .se { color: #ae81ff; }

.highlight .sh { color: #e6db74; }

.highlight .si { color: #e6db74; }

.highlight .sx { color: #e6db74; }

.highlight .sr { color: #e6db74; }

.highlight .s1 { color: #e6db74; }

.highlight .ss { color: #e6db74; }

.highlight .bp { color: #f8f8f2; }

.highlight .vc { color: #f8f8f2; }

.highlight .vg { color: #f8f8f2; }

.highlight .vi { color: #f8f8f2; }

.highlight .il { color: #ae81ff; }

/*# sourceMappingURL=styles.css.map */