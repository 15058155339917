/**
 * From: https://github.com/philipwalton/solved-by-flexbox/blob/master/assets/css/components/site.css
 * 1. Avoid the IE 10-11 `min-height` bug.
 * 2. Set `flex-shrink` to `0` to prevent some browsers from
 *    letting these items shrink to smaller than their content's default
 *    minimum size. See http://bit.ly/1Mn35US for details.
 * 3. Use `%` instead of `vh` since `vh` is buggy in older mobile Safari.
 */

.site {
  display: flex;
  flex-direction: column;
  height: 100%; /* 1, 3 */
}

header {
  margin: 10px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.05);
}

footer {
  margin: 10px;
  border-top: 2px solid rgba(0, 0, 0, 0.05);
}

header, footer {
  flex: none; /* 2 */
}

main {
  flex: 1 0 auto; /* 2 */
  padding: 0;
  width: 100%;
}
main::after {
  content: '\00a0'; /* &nbsp; */
  display: block;
  margin-top: 0;
  height: 0px;
  visibility: hidden;
}

main--full {
  padding: 0;
}
main--full::after {
  content: none;
}
