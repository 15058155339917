.article h1 {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.8em;
}

.article h2 {
  margin-top: 25px;
  margin-bottom: 0px;
}

.article {
    font-family: 'Open Sans', sans-serif;
    font-size: 20px;
    margin-left: 15px;
    margin-right: 15px;
    line-height: 1.5;
}

.article img {
  object-fit: cover;
  max-width: 100%;
}

.article-date {
  font-family: Roboto, sans-serif;
  color: #738a94;
  font-size: 0.9rem;
  line-height: 1em;
  font-weight: 500;
  letter-spacing: .5px;
  text-transform: uppercase;
  margin-left: 15px;
}

.series-section {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

/* center series */
.series-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.series-section ol {
  margin: 0;
}

.series-container {
  font-family: arial;
  display: inline-block;
  border-radius: 5px;
  border: 1px solid #dbdbdb;
  margin: 1em;
}

.series-title {
  padding-left: 10px;
}

.series-list {
  padding: 0;
}

.series-item {
  border-top:1px solid #dbdbdb;
  margin: 0;
}

.series-item.current {
  background: #e6f1fa;
  padding: 10px;
}

/* remove standard decoration for series links */
.series-item a {
  display: inline-block;
  height: 100%;
  width: 100%;
  color: inherit;
  text-decoration: none;
  outline: none;
  box-shadow: none;
  border: none;
  padding: 10px;
}
.series-item a:hover {
  background:rgba(240,240,240, 0.5);
}
.series-item a:visited, .series-item a:focus, .series-item a:hover {
  color: black;
  text-decoration: none;
  outline: none;
  box-shadow: none;
  border: none;
}

.article blockquote {
  margin: 25px 25px 25px 25px;
}


@media all and (max-width: 768px), (max-device-width: 768px) {
  .article {
      font-size: 18px;
  }
}