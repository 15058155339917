nav {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center; /* vertically center content */
  font-family: roboto, sans-serif;
  font-size: 30px;
}

/* push remaining content of nav to the right */
nav .logo {
  margin-right: auto;
}

/* fontawesome icons */
nav .social-icon {
  color: #6c94b2;
  font-size: 20px;
}
nav .social-icon:hover {
  color: #2f5673;
}

nav ul {
  display: flex;
  flex-flow: row wrap;

  /* remove standard decoration for lists */
  list-style-type: none;
  margin: 0;
  padding: 0;
}

/* remove standard decoration for nav links */
nav a {
  color: inherit;
  text-decoration: none;
  outline: none;
  box-shadow: none;
  border: none;
}
nav a:visited, nav a:focus, nav a:hover {
  color: black;
  text-decoration: none;
  outline: none;
  box-shadow: none;
  border: none;
}

/* fit link tag to its contents (the icon) */
nav li a {
  display: inline-block;
  height: 100%;
  overflow: auto;
}

/* highlight currently active link */
nav li a.active {
  position: relative;
  border-bottom: 2px solid #4285F4;
}

@media all and (max-width: 768px), (max-device-width: 768px) {
  /* place links into single column*/
  nav, nav ul {
    flex-basis: 100%;
    justify-content: center;
  }

  nav .logo {
    margin: 0; /* undo pushing other nav content */
    /*font-size: 50px;*/
  }

  nav .social-icon {
    /*font-size: 20px;*/
  }
}