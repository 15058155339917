.profile {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

.profile {
  background-size: cover;
  background-image: url('/assets/images/profile_background.jpg');
  background-size: cover;
  background-position: center;
}

.profile h1, .profile h2 {
  font-weight: 500;
  margin: 0;
  /*letter-spacing: 0.02em;*/
}

.profile h2 a {
  position: relative;
  display: inline-block;
  text-decoration: none;
  color: white;

}
.profile h2 a::after, .profile h2 a::before {
  position: absolute;
  left: 0;
  content: '';
  width: 100%;
  height: 1px;
  background-color: white;
  opacity: 0;
}

.profile h2 a:hover::after {
  bottom: 0;
  opacity: 1;
}
.profile h2 a:hover {
  color: white;
 }

.profile_card {
  height: 100%;
  display: flex;
  flex-wrap: wrap;

  justify-content: center;
  align-items: center;

  font-family: roboto, sans-serif;
  font-size: 18px;
  color: white;

  padding: 10px;
}

.profile_card h2 {
  font-size: 1em;
  font-style: italic;
}

.user {
  display: inline-block;
  width: 150px;
  height: 150px;
  border-radius: 50%;

  object-fit: cover;
  object-position: 30%;
}


.profile_message {
  padding: 10px;
}

.profile ul {
  display: flex;
  flex-flow: row wrap;

  /* remove standard decoration for lists */
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.profile .social-icon {
  color: #6c94b2;
  font-size: 20px;
}
.profile .social-icon:hover {
  color: #2f5673;
}
