.readme_container {
  font-family: Open Sans, Helvetica, Arial, sans-serif;
  font-size: 20px;
  background: #fff;

  border: 1px solid #dbdbdb;
  border-radius: 3px;
  box-shadow: 1px 2px 4px 0 rgba(0,0,0,0.18);
  margin: 1.1em auto 1.30em;
  overflow: hidden;
  max-width: 620px;
}

.readme_github_logo {
  width: 1.15em;
  max-width: 1.1em;
  display: inline-block;
  left: 0px;
  margin-right: 0.3em;
  vertical-align: -0.18em;
  filter: invert(0);
}

.readme_overview {
  padding: 0.8em 0.5em;
  border-bottom: 1px solid #dbdbdb;
}
.readme_overview h2 {
  font-weight: 400;
  font-size: 1.2em;
  line-height: 1.3em;
  margin: 0em 0px 0.5em;
}
.readme_overview h3 {
  font-weight: 400;
  margin: 0;
  margin-left: 0.15em;
  font-size: 0.8em;
  line-height: 1.05em;
}

.github_readme_body {
  font-size: 0.7em;
  line-height: 1.4em;
  text-align: left;
  min-height: 100px;
  max-height: calc(21vw + 165px);

  padding: 1em 0.5em;
  font-family: Open Sans, Helvetica, Arial, sans-serif;
  overflow: hidden;
}

.github_button_container {
  text-align: center;
  padding: 0.8em 0 1.35em;
  box-shadow: 0px 0px 60px 42px #fff;
  position: relative;
  z-index: 100;
}

.github_button_container a {
  text-decoration: none;
}

.github_button {
  color: #0366d6;
  background-color: #f1f8ff;
  border-radius: 3px;
  line-height: 20px;
  opacity: .9;
  font-weight: bold;
  border: 1px solid #0366d6;

  padding: 0.28em 1.2em;
  font-size: 0.75em;
}
