@import url("https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap");
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap');

html, body {
  height: 100%;
  max-height: 100%;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin: 0;
}

body {
  display: flex;
  flex-direction: column;

  max-width: 848px;
  margin: 0 auto;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0 0 0.5rem;
  font-weight: 500;
  letter-spacing: 0.02em;
}

h1, h2, h3, h4, h5, h6, p, blockquote, pre, ul, ol, dl, figure {
  margin: 0;
  margin-top: 15px;
  margin-bottom: 15px;
}




